import { BrowserModule } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { NgModule, Inject } from '@angular/core';

import { AppComponent } from './app.component';

import { SharedModule, BrandService } from '@whitehatgaming/shared';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { environment } from '../environments/environment';
import { CustomMessageComponent } from './components/custom-message/custom-message.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    AppComponent,
    CustomMessageComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, // first to declare wins
    SharedModule.forRoot(null, environment),
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(@Inject(DOCUMENT) private document: Document, private brand: BrandService) {

    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.rel = 'stylesheet';

    this.brand.platformSetupSubject.subscribe(() => {

      style.href = this.brand.url + '/css/whg-iframe/theme.css';

      head.appendChild(style);

    });
  }
}
